import {
  Flex,
  Text,
  Image,
  Wrap,
  WrapItem,
  Button,
  useDisclosure,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  Box,
} from "@chakra-ui/react";
import { memo } from "react";
import { Link, NavLink } from "react-router-dom";
import LogoImage from "../imgs/upacity_logo.svg";

import { DrawerMenu } from "../components/DrawerMenu";

export const HeaderNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const moveToUpacity = () => {
    window.open("https://app.upacity.jp/", "_blank");
    onClose();
  };

  return (
    <Flex
      bg="white"
      px={{ base: "10px", md: "30px" }}
      py={{ base: "8px", md: "0px" }}
      justify="space-between"
      pos="sticky"
      top="0"
      zIndex="99"
    >
      <Flex align="center" py="16px">
        <Link to="/">
          <Image
            src={LogoImage}
            w={{ base: "140px", md: "180px" }}
            alt="upacity-logo"
          />
        </Link>
      </Flex>
      <Flex align="center">
        <Wrap mr="30px" spacing="40px" display={{ base: "none", lg: "block" }}>
          <WrapItem className="navItem" transition="all 0.3s">
            <Link to="/">ホーム</Link>
          </WrapItem>
          <WrapItem className="navItem" transition="all 0.3s">
            <Link to="/learning-roadmap">使い方</Link>
          </WrapItem>
          <WrapItem className="navItem" transition="all 0.3s">
            <Link to="/learning-management">その他機能</Link>
          </WrapItem>
          {/* <WrapItem className="navItem" transition="all 0.3s">
            <Link to="/mentor-scholarship">学習コミュニティ</Link>
          </WrapItem> */}
          {/* 開発中 */}

          <WrapItem
            transition="all 0.3s"
            _hover={{
              cursor: "pointer",
              color: "#4365DD",
              transition: "all 0.3s",
            }}
          >
            <NavLink to="/userinterview">
              <Text>活用事例</Text>
            </NavLink>
          </WrapItem>
        </Wrap>
        <Button
          size={"sm"}
          display={{ base: "none", md: "block" }}
          variant={"orange"}
          fontSize="18px"
          onClick={moveToUpacity}
        >
          無料で始める
        </Button>
        <Button
          size={"s"}
          display={{ base: "block", md: "none" }}
          variant={"orange"}
          fontSize="14px"
          onClick={moveToUpacity}
          _focus={{
            outline: "none",
          }}
        >
          無料で始める
        </Button>

        {/* Drewer Menu */}
        <Box display={{ base: "block", lg: "none" }}>
          <DrawerMenu />
        </Box>
      </Flex>
    </Flex>
  );
};

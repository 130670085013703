import { useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Wrap,
  WrapItem,
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

export const DrawerMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const moveToUpacity = () => {
    window.open("https://app.upacity.jp/", "_blank");
    onClose();
  };

  return (
    <>
      <Button
        ref={btnRef}
        colorScheme="teal"
        onClick={onOpen}
        variant={"none"}
        size={"humSize"}
        _focus={{
          outline: "none",
        }}
      >
        <HamburgerIcon />
      </Button>
      <Drawer
        placement="top"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg="navy" color="white">
          <DrawerCloseButton
            _focus={{
              outline: "none",
            }}
          />

          <DrawerBody textAlign="center" w="100%">
            <Wrap>
              <WrapItem
                w="100%"
                justifyContent="center"
                py={4}
                onClick={onClose}
              >
                <Link to="/">ホーム</Link>
              </WrapItem>
            </Wrap>
            <Wrap>
              <WrapItem
                w="100%"
                justifyContent="center"
                py={4}
                onClick={onClose}
              >
                <Link to="/learning-roadmap">使い方</Link>
              </WrapItem>
            </Wrap>
            <Wrap>
              <WrapItem
                w="100%"
                justifyContent="center"
                py={4}
                onClick={onClose}
              >
                <Link to="/learning-management">その他の機能</Link>
              </WrapItem>
            </Wrap>

            <Wrap>
              <WrapItem
                w="100%"
                justifyContent="center"
                py={4}
                onClick={onClose}
              >
                <Link to="/userinterview">活用事例</Link>
              </WrapItem>
            </Wrap>
            <Button my={4} variant={"orange"} onClick={moveToUpacity}>
              無料で始める
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

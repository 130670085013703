import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Box, Flex, Text, Heading, Image, Container } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { WeSupportYou } from "../components/WeSupportYou";

import RoadMapHero from "../imgs/leaning-roadmap-page/roadmap-hero-latest.png";
import yLeaf from "../imgs/leaning-roadmap-page/y-leaf.png";
import Study from "../imgs/leaning-roadmap-page/study.png";
import Study2 from "../imgs/leaning-roadmap-page/study2.png";

import Beginner from "../imgs/leaning-roadmap-page/programming-junior.png";
import Java from "../imgs/leaning-roadmap-page/java.png";
import DataBase from "../imgs/leaning-roadmap-page/database.png";
import Exam from "../imgs/leaning-roadmap-page/exam.png";
import Network from "../imgs/leaning-roadmap-page/network.png";

import Roadmap1 from "../imgs/leaning-roadmap-page/roadmap_how_to1.jpg";
import Roadmap2 from "../imgs/leaning-roadmap-page/roadmap_how_to2.jpg";
import Roadmap3 from "../imgs/leaning-roadmap-page/roadmap_how_to3.jpg";
import Roadmap4 from "../imgs/leaning-roadmap-page/roadmap_how_to4.jpg";
import Roadmap5 from "../imgs/leaning-roadmap-page/roadmap_how_to5.jpg";

export const LeaningRoadMap = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Gsap function
  gsap.registerPlugin(ScrollTrigger);

  const target = useRef();
  const targets = gsap.utils.selector(target);
  useEffect(() => {
    gsap.fromTo(
      targets(".shape"),
      {
        opacity: 0,
        y: 0,
        scale: 0.2,
        duration: 1.3,
        stagger: 0.4,
        ease: "Power1.easeInOut",
      },
      {
        opacity: 1,
        y: 10,
        scale: 1.1,
        stagger: 0.4,
        duration: 1.3,
        ease: "Power1.easeInOut",
      }
    );
  }, []);

  useEffect(() => {
    gsap.from(targets(".ProgramingSubjectCard"), {
      duration: 1,
      y: 10,
      opacity: 0,
      ease: "power1.out",
      stagger: {
        from: "start",
        amount: 1.3, // 0.8秒おきに
      },
      scrollTrigger: {
        trigger: ".ProgramingSubjectSection",
      },
    });
  }, []);

  return (
    <Box ref={target}>
      <Box
        className="roadMapHero"
        overflow="hidden"
        mx="auto"
        pt={{ base: "35px", md: "70px" }}
      >
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="space-between"
          mx="auto"
          display={{ base: "block", md: "flex" }}
        >
          <Box
            w={{ base: "100%", md: "50%" }}
            pl={{ base: "20px", md: "30px", lg: "7%" }}
            pt={{ base: "15px", md: "100px" }}
            pb={{ base: "15px", md: "156px" }}
            textAlign="left"
            pos="relative"
          >
            <Box w={{ base: "90%", md: "68%" }} mx="auto">
              <Heading
                fontFamily={"pop"}
                fontSize={{ base: "28px", md: "40px" }}
                color={"headingNavy"}
                textAlign="left"
              >
                学習ロードマップ
                <br />
                自動作成機能
              </Heading>

              <Text
                mt="30px"
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight={{ base: "400" }}
              >
                学習内容をセットするだけで、Upacityがその分野の最適なロードマップを自動で作成します。
                <br />
                学習パスが分からなくて悩むことはもうありません。
              </Text>
            </Box>

            <Box
              display={{ base: "none", md: "block" }}
              w={{ base: "6%", md: "10%" }}
              pos="absolute"
              top="0"
              left="0"
              className="shape"
            >
              <Image
                w="100%"
                h="auto"
                src={yLeaf}
                pos="absolute"
                top={{ base: "2", md: "4" }}
                left={{ base: "5", md: "20" }}
                objectFit="cover"
              />
            </Box>

            <Box
              display={{ base: "block", md: "none" }}
              w={{ base: "6%", md: "10%" }}
              pos="absolute"
              top="0"
              right="0"
              className="shape"
            >
              <Image
                w="100%"
                h="auto"
                src={yLeaf}
                pos="absolute"
                top="5"
                right="6"
                objectFit="cover"
                transform="rotate(-90deg)"
              />
            </Box>

            <Box
              display={{ base: "none", md: "block" }}
              w={{ base: "10%", md: "15%" }}
              pos="absolute"
              bottom="0"
              left="0"
              className="shape"
            >
              <Image
                w="100%"
                h="auto"
                src={Study}
                pos="absolute"
                bottom={{ base: "0", md: "6" }}
                left={{ base: "5", md: "10" }}
                objectFit="cover"
              />
            </Box>

            <Box
              display={{ base: "none", md: "none" }}
              w={{ base: "10%", md: "15%" }}
              pos="absolute"
              bottom="0"
              right="0"
              className="shape"
            >
              <Image
                w="100%"
                h="auto"
                src={Study2}
                pos="absolute"
                bottom="-2"
                right="5"
                objectFit="cover"
              />
            </Box>
          </Box>

          <Box
            className="roadMapHero"
            w={{ base: "100%", md: "64%" }}
            pt={{ base: "20px", md: "0px" }}
          >
            <Image src={RoadMapHero} mb={{ base: "-80px", md: "-190px" }} />
          </Box>
        </Flex>
      </Box>

      <Box py={{ base: "10", md: "20" }} px="5" bg="mainGray">
        <Container maxW={"980px"} px={"0"}>
          <Heading
            fontSize={{ base: "lg", md: "xl" }}
            mb="15px"
            color="#252525"
            textAlign="center"
          >
            ロードマップ機能での
            <br className="brDisplay" />
            学習の進め方
          </Heading>
          <Text
            mt={"14px"}
            fontWeight={"medium"}
            fontSize={{ base: "s", md: "m" }}
          >
            学習内容をセットしたら、Upacityがロードマップを生成します。
            <br diaplay={{ base: "block", md: "none" }} />
            あとはそれに沿って、必要な学習を進めるだけです。
          </Text>
        </Container>
      </Box>

      <Box
        py={{ base: "10", md: "20" }}
        px="5"
        bg="grayBg"
        className="howToUseSection"
      >
        <Container maxW={"980px"} px={"0"}>
          <Flex
            justify="space-between"
            flexDir={{ base: "column", md: "row" }}
            className="planCard"
            py={"80px"}
          >
            <Image
              src={Roadmap1}
              alt="roadmap_how_to"
              w={{ base: "100%", md: "480px" }}
              h={"100%"}
            />
            <Box
              w={{ base: "100%", md: "44%" }}
              py={{ base: "8", md: "10" }}
              textAlign="left"
            >
              <Text fontSize={"21px"} textAlign="left" mb={"40px"}>
                <Text
                  as={"span"}
                  fontSize={"40px"}
                  color={"#6B6BF4"}
                  pr={"16px"}
                  fontFamily={"Cabin"}
                >
                  01
                </Text>
                学習分野の設定
              </Text>
              <Text fontSize={"16px"} fontWeight="400" textAlign="left">
                サイドバーからロードマップを作成、学習分野を入力し検索、または既存のコースから選択します。
                <br />
                その分野でさらに絞りたい学習言語などがあれば、さらに詳しく検索にかけます。
              </Text>
            </Box>
          </Flex>

          <Flex
            justify="space-between"
            flexDir={{ base: "column", md: "row" }}
            className="planCard"
            py={"80px"}
            //background={"#FAF8F7"}
          >
            <Image
              src={Roadmap2}
              alt="roadmap_how_to2"
              w={{ base: "100%", md: "480px" }}
              h={"100%"}
              border={"1px solid #cdcdcd"}
              borderRadius={"6px"}
            />
            <Box
              w={{ base: "100%", md: "44%" }}
              py={{ base: "8", md: "10" }}
              textAlign="left"
            >
              <Text fontSize={"21px"} textAlign="left" mb={"40px"}>
                <Text
                  as={"span"}
                  fontSize={"40px"}
                  color={"#6B6BF4"}
                  pr={"16px"}
                  fontFamily={"Cabin"}
                >
                  02
                </Text>
                ロードマップ作成
              </Text>
              <Text fontSize={"16px"} fontWeight="400" textAlign="left">
                設定された条件でロードマップが自動作成されます。
                <br />
                設定されたタスク、その中のやることをこなしていくことで、就職に役立つ学習実績を積み重ねていきます。
              </Text>
            </Box>
          </Flex>

          <Flex
            justify="space-between"
            flexDir={{ base: "column", md: "row" }}
            className="planCard"
            py={"80px"}
          >
            <Image
              src={Roadmap3}
              alt="roadmap_how_to3"
              w={{ base: "100%", md: "480px" }}
              h={"100%"}
            />
            <Box
              w={{ base: "100%", md: "44%" }}
              py={{ base: "8", md: "10" }}
              textAlign="left"
            >
              <Text fontSize={"21px"} textAlign="left" mb={"40px"}>
                <Text
                  as={"span"}
                  fontSize={"40px"}
                  color={"#6B6BF4"}
                  pr={"16px"}
                  fontFamily={"Cabin"}
                >
                  03
                </Text>
                便利機能を活用しながら学習
              </Text>
              <Text fontSize={"16px"} fontWeight="400" textAlign="left">
                やることリストの検索アイコンをクリックすると、ご希望のプラットフォームで学習を進めていくことができます。
                <br />
                一つ一つのやることから、ダイレクトに学習に直結できることで、学習体験の向上を実現しています。
              </Text>
            </Box>
          </Flex>
          <Flex
            justify="space-between"
            flexDir={{ base: "column", md: "row" }}
            className="planCard"
            py={"80px"}
          >
            <Image
              src={Roadmap4}
              alt="roadmap_how_to3"
              w={{ base: "100%", md: "480px" }}
              h={"100%"}
            />
            <Box
              w={{ base: "100%", md: "44%" }}
              py={{ base: "8", md: "10" }}
              textAlign="left"
            >
              <Text fontSize={"21px"} textAlign="left" mb={"40px"}>
                <Text
                  as={"span"}
                  fontSize={"40px"}
                  color={"#6B6BF4"}
                  pr={"16px"}
                  fontFamily={"Cabin"}
                >
                  04
                </Text>
                学習レポートを作成
              </Text>
              <Text fontSize={"16px"} fontWeight="400" textAlign="left">
                設定された”やること”に対し、レポートを作成できます。
                <br />
                作成されたレポートは、提出するとAIが自動評価。レポートに対してフィードバック・スコア評価を行い、学習定着度を可視化します。
              </Text>
            </Box>
          </Flex>

          <Flex
            justify="space-between"
            flexDir={{ base: "column", md: "row" }}
            className="planCard"
            py={"80px"}
          >
            <Image
              src={Roadmap5}
              alt="roadmap_how_to3"
              w={{ base: "100%", md: "480px" }}
              h={"100%"}
            />
            <Box
              w={{ base: "100%", md: "44%" }}
              py={{ base: "8", md: "10" }}
              textAlign="left"
            >
              <Text fontSize={"21px"} textAlign="left" mb={"40px"}>
                <Text
                  as={"span"}
                  fontSize={"40px"}
                  color={"#6B6BF4"}
                  pr={"16px"}
                  fontFamily={"Cabin"}
                >
                  05
                </Text>
                マイレポートで学習評価を確認
              </Text>
              <Text fontSize={"16px"} fontWeight="400" textAlign="left">
                マイレポートページでは、提出したレポートの評価結果を確認できます。AI機能で正確に導き出したこの評価は、就職活動の際に学習経歴を証明する、強いアピール材料として活用できます。
                <br />
                レポート以外にも、学習進捗を各モジュール単位で多角的に確認することができます。
              </Text>
            </Box>
          </Flex>
        </Container>
      </Box>

      <WeSupportYou />
    </Box>
  );
};

import { Box, Flex, VStack, Text, Heading, Image } from "@chakra-ui/react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import Mock from "../imgs/Img.png";
import RoadMap from "../imgs/roadmap_img.png";
import Interview from "../imgs/Interview_img.png";
import Mentor from "../imgs/mentor_img.png";
import Allow from "../imgs/arrow_tri.svg";
import { WeSupportYou } from "../components/WeSupportYou";

export const UserInterviewContent = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  console.log(location.pathname);

  const postsData = props.posts;

  const parse = require("html-react-parser");

  return (
    <Box pt={{ base: "10", md: "20" }} pb={0} px="5">
      <Heading
        mb="30px"
        fontSize={{ base: "24px", md: "36px" }}
        fontFamily="pop"
        fontWeight="600"
        color="#252525"
        textAlign="center"
      >
        活用事例
      </Heading>
      <Text
        mt={"14px"}
        fontWeight={"medium"}
        fontSize={{ base: "s", md: "md" }}
        mb={{ base: "60px", md: "100px" }}
      >
        Upacityを活用するのに最適な、
        <br />
        複数の事例をご紹介します。
      </Text>

      <VStack
        maxW={{ base: "96%", md: "980px" }}
        m={"auto"}
        spacing={"160px"}
        mb={"80px"}
      >
        <VStack spacing={"64px"}>
          <Box
            w={"100%"}
            p={"24px"}
            bg={"#113366"}
            color={"#fff"}
            borderRadius={"8px"}
          >
            <Text fontWeight={"400"} fontSize={{ base: "18px", md: "21px" }}>
              学習を始めたいが、自分の目指している職種で
              <br />
              どのような流れで学習を進めていくべきなのかがわからない
            </Text>
          </Box>
          <Image src={Allow} />
          <Flex alignItems={"center"} flexDir={"column"}>
            <Image src={RoadMap} w={{ base: "80%", md: "300px" }} mb={"80px"} />
            <Text
              fontWeight={"400"}
              fontSize={{ base: "16px", md: "18px" }}
              color={"#333"}
            >
              Upacityのロードマップ作成機能で、最初に目標とする職種を選択し
              <br />
              それに関連するスキルや知識を習得するために必要な、ステップを示したロードマップが作成されます。
              <br />
              Upacityを使えば、自分の目指す職種に向けて学習を進めるための道筋が明確になり、迷わずに学び続けることができます。
            </Text>
          </Flex>
        </VStack>
        <VStack spacing={"64px"}>
          <Box
            w={"100%"}
            p={"24px"}
            bg={"#113366"}
            color={"#fff"}
            borderRadius={"8px"}
          >
            <Text fontWeight={"400"} fontSize={{ base: "18px", md: "21px" }}>
              本当に自分の学習が、本当に就職につながるのかが
              <br />
              わからない
            </Text>
          </Box>
          <Image src={Allow} />
          <Flex alignItems={"center"} flexDir={"column"}>
            <Image
              src={Interview}
              w={{ base: "80%", md: "300px" }}
              mb={"40px"}
            />
            <Text
              fontWeight={"400"}
              fontSize={{ base: "16px", md: "18px" }}
              color={"#333"}
            >
              Upacityカリキュラムは実際にたくさんの現役エンジニアを輩出してきた実績があります。
              <br />
              ご自身で学習した内容は精査され、自動でスコアがでたりフィードバックを見ることができます。
              <br />
              学習の進捗を可視化することでご自身からも、
              企業側からもどのようなスキルを現時点で獲得できているかがわかりやすく、就職の際のミスマッチを防ぐのに大変役立ちます。
            </Text>
          </Flex>
        </VStack>
        <VStack spacing={"64px"}>
          <Box
            w={"100%"}
            p={"24px"}
            bg={"#113366"}
            color={"#fff"}
            borderRadius={"8px"}
          >
            <Text fontWeight={"400"} fontSize={{ base: "18px", md: "21px" }}>
              学習に躓いた時に、相談できる人が欲しい。
              <br />
              同じく学習をしている人たちとも繋がってみたい。
            </Text>
          </Box>
          <Image src={Allow} />
          <Flex alignItems={"center"} flexDir={"column"}>
            <Image src={Mentor} w={{ base: "80%", md: "300px" }} mb={"40px"} />
            <Text
              fontWeight={"400"}
              fontSize={{ base: "16px", md: "18px" }}
              color={"#333"}
            >
              Upacityでは会員用Slackコミュニティーを用意しています。
              <br />
              現役メンターへの24時間の質問受付、また同じ目標に向かって学習している仲間とも
              横のつながりを持つことが可能です。
            </Text>
          </Flex>
        </VStack>
      </VStack>

      <WeSupportYou />
    </Box>
  );
};
